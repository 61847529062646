<template>
  <div id="hermeneus-feedback" class="hermeneus-feedback">
    <button @click="toggleExpanded()" :disabled="disabled_if" :class="button_style" class="hermeneus-feedback__button-expand flex-group-start flex-items-center">
      <hermeneus-icon :name="this.icon_name" :color="icon_color" :width="button_size" :height="button_size"></hermeneus-icon>
    </button>
    <div v-if="this.expanded && !submitted && !this.loading" class="hermeneus-feedback__expanded-container flex-column flex-group-center flex-items-stretch w100 mt2 shadow-lg">
      <textarea class="p2 h-full text-grey-800 rounded-t" type="text" v-model="comment" placeholder="Dein Kommentar..."></textarea>
      <button class="hermeneus-feedback__button-submit button-red p2 rounded-b" @click="submit">Senden</button>
    </div>
    <loading-dots v-if="this.expanded && this.loading" color="red" class="pt-3 mt2 pos-absolute"></loading-dots>
    <div v-if="this.expanded && this.submitted && !this.loading" class="pos-absolute text-center pt-3">
      <span class="inline-block secondary rounded-lg bg-white p-2">❤ Danke!</span>
    </div>
  </div>
</template>

<script>
import {wait} from "../helpers/functions_helpers";
import axios from 'axios';

export default {
  name: "hermeneus-feedback",
  props: {
    commentable_id: {
      required: false,
      default: null
    },
    commentable_type: {
      required: false,
      default: null
    },
    disabled_if: {
      required: false,
      default: false
    },
    subject: {
      required: true,
      default: 'Nicht bekannt'
    },
    button_style: {
      required: false,
    },
    button_size: {
      required: false,
      default: 24
    },
    icon_name: {
      required: false,
      default: 'hi-comment'
    },
    icon_color: {
      required: false,
      default: 'hi-color-white'
    }
  },
  data: function () {
    return {
      expanded: false,
      loading: false,
      submitted: false,
      comment: '',
    }
  },
  methods: {
    toggleExpanded () {
      this.expanded = !this.expanded;
    },
    async submit () {
      this.loading = true;
      axios.post('/feedback', {
        url: window.location.pathname,
        subject: this.subject,
        comment: this.comment,
        commentable_id: this.commentable_id,
        commentable_type: this.commentable_type,
      }).then(async (response) => {
        await wait(1000);
        this.loading = false;
        this.submitted = true;
        await wait(2000);
        this.toggleExpanded();

      });

    },
    closeIfClickedOutside (event) {
      if (!event.target.closest('#hermeneus-feedback') && !event.target.closest('.hermeneus-feedback__expanded-container')) {
        this.loading = false;
        this.expanded = false;
        document.removeEventListener('click', this.closeIfClickedOutside);
      }
    },
  },
  computed: {
    isAnyOpen: function () {
      return this.expanded === true;
    }
  },
  watch: {
    isAnyOpen (isOpen) {
      if (isOpen) {
        document.addEventListener(
            'click',
            this.closeIfClickedOutside
        );
      }
    }
  },
  mounted: function () {
  }
}
</script>

<style scoped>
.hermeneus-feedback {
  position: relative;
}

.hermeneus-feedback__expanded-container {
  border-radius: 5px;
  width: 20rem;
  position: absolute;
  height: 8rem;
  right: 0;
}

.hermeneus-feedback__expanded-container textarea {
  border-radius: 5px 5px 0px 0px;
  background: #fafafa;
  border: 1px solid #ccc;
  outline: none;
  height: 100%;
}

.hermeneus-feedback__expanded-container textarea:focus {
  border-top: 1px solid #B22E2F;
  border-right: 1px solid #B22E2F;
  border-left: 1px solid #B22E2F;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
}

.hermeneus-feedback__btn-title {
  display: none;
}

@media only screen and (min-width: 768px) {
  .hermeneus-feedback__btn-title {
    display: inline-block;
  }
}

</style>